import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HtmlToReactParser from "html-to-react";

const htmlParser = HtmlToReactParser.Parser;

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return <i className="fal fa-angle-left slick-next" onClick={onClick}></i>;
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return <i className="fal fa-angle-right slick-prev" onClick={onClick}></i>;
};

const HeroSlider = ({ sliderData }) => {
  const slider1 = useRef(null);
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const htmlToReactParser = new htmlParser();

  const SliderCard = (data) => {
    return (
      <div
        class="hero-slide-item"
        style={{ backgroundImage: "url(" + data.data.image + ")" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="hero-text">
                {htmlToReactParser.parse(data.data.text)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Slider {...settings} ref={slider1} className="hero-area">
      {sliderData.map((slide, index) => (
        <SliderCard key={index} data={slide} />
      ))}
    </Slider>
  );
};

export default HeroSlider;
