import React from "react";
import HeroSlider from "../components/HeroSlider";
import Layout from "../components/Layout";
import PizzaBlock from "../components/PizzaBlock";
import StoreInformation from "../components/StoreInformation";
import { data } from "../data/index";
import FoodImage1 from "../images/img-1.jpg";
import FoodImage2 from "../images/donair-1.jpg";
import FoodImage3 from "../images/pizza-block-1.jpg";

// markup
const IndexPage = () => {
  return (
    <Layout title="Tasty Pizza – Dartmouth’s Source for Pizza, Donairs, Seafood & Subs">
      <HeroSlider sliderData={data.indexPage.slider} />
      <StoreInformation />
      <PizzaBlock
        showPhoneNumber={true}
        heading={data.indexPage.sectionOne.title}
        description={data.indexPage.sectionOne.description}
        image={FoodImage1}
        secondBelow={data.indexPage.sectionOnesecondBelow}
        menuLink={data.indexPage.sectionOne.checkoutLink}
        menuText={data.indexPage.sectionOne.checkouttext}
        phoneNumber={data.indexPage.sectionOne.ctaNumber}
      />
      <PizzaBlock
        showPhoneNumber={true}
        heading={data.indexPage.sectionTwo.title}
        description={data.indexPage.sectionTwo.description}
        image={FoodImage2}
        menuLink={data.indexPage.sectionTwo.checkoutLink}
        menuText={data.indexPage.sectionTwo.checkouttext}
        imageLeft={true}
      />
      <PizzaBlock
        showPhoneNumber={true}
        heading={data.indexPage.sectionThree.title}
        description={data.indexPage.sectionThree.description}
        image={FoodImage3}
        menuLink={data.indexPage.sectionThree.checkoutLink}
        menuText={data.indexPage.sectionThree.checkouttext}
        secondDescription={data.indexPage.sectionThree.belowDescription}
      />
    </Layout>
  );
};

export default IndexPage;
