import React from "react";
import { data } from "../data"

const StoreInformation = () => {
  const { storeInfo } = data
  console.log(storeInfo)
  return (
    <div className="start-time-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="start-time-warp">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="start-info">
                    <h2>{storeInfo.name}</h2>
                    <p>
                      {storeInfo.address}
                    </p>
                    <p>{storeInfo.phone}</p>
                    <p>{storeInfo.email}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="start-time">
                    <h2>{storeInfo.message}</h2>
                    <div className="row">
                      {storeInfo.schedule.map(sch =>
                        <div className="col-lg-6 col-md-6">
                          <div className="time-table">
                            <span>{sch.days}</span>
                            <h4>{sch.time}</h4>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreInformation;
