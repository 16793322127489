import React from "react";
import { Link } from "gatsby";

const PizzaBlock = ({
  heading,
  description,
  menuText,
  menuLink,
  image,
  phoneNumber,
  imageLeft,
  secondDescription,
  secondBelow,
}) => {
  return (
    <div className="checkout-area">
      <div className="container">
        <div className="row align-items-center">
          {imageLeft && (
            <div className="col-lg-6 col-md-6 order-last order-md-first imagePizzaBlock">
              <div className="checkout-thumb">
                <img src={image} alt="" />
              </div>
            </div>
          )}
          <div className="col-lg-6 col-md-6">
            <div className="checkout-text pr-2">
              <h1>{heading}</h1>
              <p>{description}</p>
              <div className="checkout-bottom">
                {menuText && (
                  <Link className="common-btn" to={menuLink}>
                    {menuText}
                  </Link>
                )}

                {phoneNumber && (
                  <span>
                    Call Us Now! <br />
                    {phoneNumber}
                  </span>
                )}
              </div>

              <div className="ck-bottom-txt">
                <p>{secondDescription} </p>
                <p>
                  {secondDescription
                    ? "We’re open 7 days a week and offer free parking."
                    : undefined}
                </p>
              </div>
            </div>
          </div>
          {!imageLeft && (
            <div className="col-lg-6 col-md-6 imagePizzaBlock">
              <div className="checkout-thumb">
                <img src={image} alt="" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PizzaBlock;
